<template>
    <div class="content">
        <div style="padding-top: 15vh;">
            <div class="login-box">
                <div class="form">
                    <div class="logo">
                        <img src="~@/assets/login.png" alt="">
                    </div>
                    <h4>忘记密码</h4>
                    <input type="text" placeholder="手机号/用户名/邮箱" v-model="userName" />
                    <input type="passWord" placeholder="请输入旧密码" v-model="passWord" />
                    <input type="passWord" placeholder="请输入新密码" v-model="forgetPassWord" />
                    <div class="statuc">
                        <p @click="navTo('/')">账号登陆</p>
                    </div>
                    <button class="btn" @click="PostForgetPassword">修改</button>
                </div>
                <div class="login—banner">
                    <img src="~@/assets/login-banner.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            userName: 'admin',
            passWord: 'admin888',
            forgetPassWord: 'admin88',
        }
    },
    created() {
    },
    methods: {
        navTo(url) {
            this.$router.push(url)
        },
        PostForgetPassword() {
            if (this.userName && this.passWord && this.forgetPassWord) {
                this.$api.ForgetPassword({
                    userName: this.userName,
                    oldPassword: this.passWord,
                    nowPassword: this.forgetPassWord
                }).then(res => {
                    console.log(this.$message({
                        message: res.msg,
                        type: 'success'
                    }));
                })
            } else {
                this.$message.error('请输入账号和密码')
            }

        }
    },
}
</script>
<style>
.content {
    min-height: 100vh;
    background-color: rgb(102, 105, 244);
}

.login-box {
    /* background-color: red; */
    border-radius: 2em;
    overflow: hidden;
    width: 60em;
    height: 30em;
    margin: 0 auto;
    /* padding-top: 30vh; */
    display: flex;
    border-radius: 2em;
}

/* .login{
    display: none;
    display: flex;
    justify-content: center;
    align-items: center;

}
.loginLeft{
    border-right: 2em solid #5874ff;
    border-top: 2.4em solid transparent;
    border-bottom: 2.4em solid transparent;
}
.loginContent{
    background-color: #5874ff;
    width: 12em;
    padding: 1em 0;
    text-align: center;
} */

.form {
    /* width: 25em; */
    width: 30em;
    background-color: #eee;
    padding: 2em;
    /* border-radius: 2em; */

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.logo {
    width: 10em;
    height: 2.4em;
    margin: 0 auto;
    padding-bottom: 1em;
}

.logo img {
    width: 100%;
    height: 100%;
}

.form h4 {
    font-size: 1em;
    color: rgb(85, 85, 85);
}

.form input {
    margin-top: 1em;
    border: 1px solid rgb(200, 199, 199);
    padding: 1em 1em;

    font-size: 1em;
    /* color: #eee; */
}

.statuc {
    margin-top: 1em;
    text-align: right;
}

.statuc p {
    color: #808080;
}

.btn {
    margin-top: 1em;
    width: 100%;
    height: 4em;
    border-radius: 0.4em;
    background-color: #e5e5e5;
    color: #999999;
    text-align: center;
    border: none;
    font-size: 1em;
}

.login—banner {
    background-color: red;
    /* width: 25em; */
    width: 30em;
    height: 100%;
}

.login—banner img {
    width: 100%;
    height: 100%;
}
</style>